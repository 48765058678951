angular.module('jobs.upload.config', ['angularFileUpload'])
.config(function($provide) {
  $provide.decorator('FileUploader', function($delegate) {
    /**
    * Callback interface
    */
    /* RemoveOne and RemoveAll are different in that they
     trigger remove status regardless of whether file is
     uploading or not, as opposed to native .cancel method
    */
    var FileUploader = $delegate;

    FileUploader.prototype.sqBeforeRemoveAll = function() {};
    
    FileUploader.prototype.sqOnRemoveAll = function() {};

    FileUploader.prototype.sqBeforeRemoveOne = function(item) {};

    FileUploader.prototype.sqOnFinishedAddingOne = function(item) {};

    // add new method
    FileUploader.prototype.sqRemoveAll = function() {
      // uses FileUploader.clearQueue() method, but removes using sqRemove()
      // and adds the callback event;
      this.sqBeforeRemoveAll();
      while (this.queue.length) {
        this.queue[0].sqRemove();
      }
      this.progress = 0;
      this.sqOnRemoveAll();
    };

    // in angularFileUpload 2.1.1 returns FileItem undefined
    // FileUploader.FileItem.prototype.sqRemoveOne = function() 
    // {   
    //     //removeFromQueue(this);
    //     this.remove();
    //     this.uploader.sqOnRemoveOne(this); //uploader === fileuploader instance
    // };

    // override default over class ("nv-file-over")
    //FileUploader.FileOver.prototype.overClass = 'your-class-name';

    return FileUploader;
  });

  $provide.decorator('FileItem', function($delegate) {
    var FileItem = $delegate;
    
    // note, the file item isn't actually guaranteed to be deleted
    // not sure if this is because all references aren't broken
    FileItem.prototype.sqRemove = function() {
      // cancels first, then 'removes', although the
      // reference to uploader isn't broken ?? removed from queue though...
      this.uploader.sqBeforeRemoveOne(this);
      this.remove(); //
      this.uploader.sqOnRemoveOne(this); // uploader === fileuploader instance
    };

    return FileItem;
  });
});
